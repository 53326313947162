import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Avatar,
  IconButton,
  Button,
} from "@mui/material";
import car1 from "../Images/DSC01004.jpg";
import car2 from "../Images/DSC01080 (1).jpg";
import car3 from "../Images/DSC01023.jpg";
import car4 from "../Images/DSC01068.JPG";
import Outlet from "../Container/outletCon";
import Serviceoffering from "./serviceoffering";
import Video from "../../Video/video.mp4";
import img1 from "../Images/DSC01017.jpg";
import img2 from "../Images/DSC01011.jpg";
import img3 from "../Images/DSC01032-Enhanced-NR.jpg";
import img4 from "../Images/DSC01037.jpg";
import img5 from "../Images/DSC01004.jpg";
import img6 from "../Images/DSC01068.JPG";
import img7 from "../Images/DSC01066.JPG";
import img8 from "../Images/DSC01073-Enhanced-NR.JPG";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import icon1 from "../../images/homeicon.png";
import icon2 from "../../images/serviceoffericon.png";
import icon3 from "../../images/videoicon.png";
import icon4 from "../../images/achievmenticon.png";
import icon5 from "../../images/testimonialicon.png";
import icon6 from "../../images/galleryicon.png";
import icon7 from "../../images/teamicon.png";
import { Link } from "react-router-dom";
import Cardspage from '../Container/card'
const images = [car1, car2, car3,car4];

const images1 = [
  { src: img1, alt: "Service 1" },
  { src: img2, alt: "Service 2" },
  { src: img3, alt: "Service 3" },
  { src: img4, alt: "Service 4" },
  { src: img5, alt: "Service 5" },
  { src: img6, alt: "Service 6" },
  { src: img7, alt: "Service 7" },
  { src: img8, alt: "Service 8" },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndex1, setCurrentIndex1] = useState(0);

  // Auto-slide every 2 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 2000);
    return () => clearInterval(timer);
  }, [currentIndex]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  const handleGetDirection = () => {
    // Replace with the actual coordinates of your office
    const latitude = 20.25435; // Example: Mg Motors Bhubaneswar latitude
    const longitude = 85.7866; // Example: Mg Motors Bhubaneswar longitude

    // Construct the Google Maps direction URL
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;

    // Open Google Maps in a new tab
    window.open(googleMapsUrl, "_blank");
  };


  const items = [
    { label: "OUR OUTLETS", img: icon1, id: "outlets" },
    // { label: "SERVICE OFFERINGS", img: icon2, id: "serviceoffering" },
    { label: "WALKAROUND VIDEO", img: icon3, id: "walkaroundvideo" },
    { label: "TESTIMONIALS", img: icon5, id: "testimonials" },
    { label: "SERVICE GALLERY", img: icon6, id: "gallery" },
  ];

  const handlePrev = () => {
    setCurrentIndex1((prevIndex1) =>
      prevIndex1 === 0 ? items.length - 1 : prevIndex1 - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex1((prevIndex1) => (prevIndex1 + 1) % items.length);
  };

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Box
        sx={{
          position: "relative",
          marginTop: "95px",
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <Box
          component="img"
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          sx={{
            width: "100%",
            height: { xs: "300px", sm: "400px", md: "500px" },
            objectFit: "cover",
            overflowX: "hidden",
          }}
        />
        <Box
          component="button"
          onClick={prevSlide}
          sx={{
            position: "absolute",
            top: "50%",
            left: "10px",
            transform: "translateY(-50%)",
            background: "rgba(0,0,0,0.5)",
            color: "#fff",
            border: "none",
            borderRadius: "50%",
            padding: "10px",
            cursor: "pointer",
            zIndex: 1,
          }}
        >
          &#8249;
        </Box>
        <Box
          component="button"
          onClick={nextSlide}
          sx={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            background: "rgba(0,0,0,0.5)",
            color: "#fff",
            border: "none",
            borderRadius: "50%",
            padding: "10px",
            cursor: "pointer",
            zIndex: 1,
          }}
        >
          &#8250;
        </Box>
      </Box>
      {/* Header carrousel  */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#444",
          padding: "20px",
          borderRadius: "10px",
          gap: "40px",
          width: "100%",
        }}
      >
        {/* Left Arrow */}
        <IconButton
          onClick={handlePrev}
          sx={{ color: "#fff", backgroundColor: "#ddd", borderRadius: "50%" }}
        >
          <ArrowBackIosNewIcon sx={{ color: "#d32f2f" }} />
        </IconButton>

        {/* Content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "nowrap",
            gap: { md: "100px" },
            overflow: "hidden",
          }}
        >
          {items.slice(currentIndex1, currentIndex1 + 4).map((item, index) => (
            <Box
              key={index}
              sx={{ textAlign: "center", color: "#fff", minWidth: "150px" }}
              onClick={() => {
                const section = document.getElementById(item.id);
                if (section) {
                  section.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              <Box
                component="img"
                src={item.img}
                alt={item.label}
                sx={{
                  width: "60px",
                  height: "50px",
                  objectFit: "contain",
                  margin: "0 auto",
                  paddingBottom: "5px",
                  cursor: "pointer",
                }}
              />
              <Typography sx={{ fontWeight: 900, cursor: "pointer" }}>
                {item.label}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Right Arrow */}
        <IconButton
          onClick={handleNext}
          sx={{ color: "#fff", backgroundColor: "#ddd", borderRadius: "50%" }}
        >
          <ArrowForwardIosIcon sx={{ color: "#d32f2f" }} />
        </IconButton>
      </Box>
      {/* Outlet */}
      <Box id="outlets">
        <Outlet />
      </Box>
      {/* Service Offering
      <Box id="serviceoffering">
        <Serviceoffering />
      </Box>
       Video */}
      <Box
        id="walkaroundvideo"
        sx={{
          position: "relative",
          height: "100vh",
          overflow: "hidden",
          marginTop: "40px",
        }}
      >
        <video
          playsinline
          preload
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        >
          <source src={Video} type="video/mp4" />
        </video>
      </Box>
      {/* Testimonials */}
      <Cardspage/>
    
      {/* Image Gallery */}
      <Box
        id="gallery"
        sx={{ padding: "40px 20px", backgroundColor: "#f9f9f9" }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{
            fontWeight: "bold",
            color: "#d32f2f",
            textTransform: "uppercase",
            marginBottom: "30px",
            fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
          }}
        >
          Service Gallery
        </Typography>

        <Grid container spacing={2}>
          {images1.map((image, index) => (
            <Grid item xs={6} sm={3} md={3} key={index}>
              <Box
                component="img"
                src={image.src}
                alt={image.alt}
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Carousel;

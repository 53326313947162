import React, { Component } from 'react';
import { Typography, Button, Grid, Box } from '@mui/material';
import './CityPricing.css'; // Import the CSS file for styling

class CityPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [
        'MG Hector Plus 7 Seater price in Guntur', 'MG Hector Plus 7 Seater price in Visakhapatnam',
        'MG Hector Plus 7 Seater price in Krishna', 'MG Hector Plus 7 Seater price in Kamrup',
        'MG Hector Plus 7 Seater price in Nellore', 'MG Hector Plus 7 Seater price in Muzaffarpur',
        'MG Hector Plus 7 Seater price in Tirupati', 'MG Hector Plus 7 Seater price in Patna',
        'MG Hector Plus 7 Seater price in Chandigarh', 'MG Hector Plus 7 Seater price in New Delhi',
        'MG Hector Plus 7 Seater price in Ambikapur', 'MG Hector Plus 7 Seater price in North Goa',
        'MG Hector Plus 7 Seater price in Bilaspur', 'MG Hector Plus 7 Seater price in South Goa',
        'MG Hector Plus 7 Seater price in Raipur', 'MG Hector Plus 7 Seater price in Ahmedabad'
      ],
      visibleCities: 8,
    };
  }

  handleLoadMore = () => {
    this.setState(prevState => ({
      visibleCities: prevState.visibleCities + 8
    }));
  };

  render() {
    const { cities, visibleCities } = this.state;
    return (
      <Box id="price" sx={{ textAlign: 'center',display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <Box sx={{display:'flex',justifyContent:'center'}}>
        <Typography variant="h6" sx={{ marginBottom: '20px', fontFamily: 'GillSansSemiBold, sans-serif',fontWeight:'600',textAlign:{md:'center',xs:'left',sm:'center'}}}>
          MG Hector City Wise Pricing
        </Typography>
        </Box>
        <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
          {cities.slice(0, visibleCities).map((city, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              {/* <a href="#" className="city-link" > */}
                {city}
              {/* </a> */}
            </Grid>
          ))}
        </Grid>
        <Button
  onClick={this.handleLoadMore}
  sx={{
    width: '200px',
    marginTop: '20px',
    backgroundColor: '#CF2230',
    color: 'white',
    fontWeight: '700',
    fontFamily: 'GillSansSemiBold, sans-serif',
    fontSize: { xs: '2.5vw', sm: '0.9vw' },
    marginLeft: { xs: '10px', sm: '0px' }, // Add margin on the left for mobile screens
    alignSelf: { xs: 'flex-start', sm: 'center' }, // Left align on mobile, center align on larger screens
    '&:hover': { backgroundColor: 'black' },
  }}
>
  Load More
</Button>

      </Box>
    );
  }
}

export default CityPricing;

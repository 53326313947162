import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Controller from "../Containers/Controller_Cont.js";
import Drawer from "../Containers/DrawerCont.js";
import Login from "../../Auth/Containers/Login_Cont.js";
import Profile from "../../Pages/Profile/Containers/ProfileCont.js";
import Home from "../../Pages/Home/Container/home.js";
import Hector from "../../Pages/MgHector/Components/Hector/Component/Hector.js";
import Hector6seater from "../../Pages/MgHector/Components/Hector6seater/Component/Hector.js";
import Hector7seater from "../../Pages/MgHector/Components/Hector7seater/Components/Hector.js";
import Hectorblackstorm from "../../Pages/MgHector/Components/Hector Blackstorm/Components/Hectorblackstorm.js";
import Gloster from "../../Pages/MgGloster/Components/Gloster/Component/Gloster.js";
import Glosterblackstorm from "../../Pages/MgGloster/Components/Gloster Blackstorm/Components/Glosterblackstorm.js";
import Glosterseries from "../../Pages/MgGloster/Components/Gloster storm Series/Components/Glosterseries.js";
import Astor from "../../Pages/MgAstor/Components/Astor.js";
import Comet from "../../Pages/MgComet/Components/Comet.js";
import ZsEv from "../../Pages/MgZsEv/Components/zs.js";
import Windsor from "../../Pages/MgWindsor/Components/Windsor.js";
import Car360 from "../../Pages/Home/Components/car360d.js";
import NavBar from "../../Pages/Home/Container/Nav.js";
import Preownedcars from "../../Pages/Preownedcars/Container/preownedCon.js";
import Car1 from "../../Pages/car-details/Container/overviewCon.js";
import Specifications from "../../Pages/car-details/Container/specificationCon.js";
import Clientnav from "../../Client/Containers/NavbarCont.js";
import Advertisement from "../../Client/Containers/advertisementCon.js";
import Oldcar from "../../Client/Containers/oldCarCont.js";
import Admindashboard from "../../Client/Containers/dashboardCon.js";
import EditPage from "../../Client/Containers/addEditCarCon.js";
import View from "../../Client/Containers/ViewpageCon.js";
import Addinginfo from "../../Client/Containers/addOldCarsCon.js";
import Overview from "../../Client/Containers/addOverviewsCon.js";
import Specification from "../../Client/Containers/addSpecificationsCon.js";
import Features from "../../Client/Containers/addFeaturesCon.js";
import Contact from "../../Pages/ContactUs/Container/ContactUsCon.js";
import About from "../../Pages/AboutUs/Components/About.js";
// import Service from '../../Pages/Services/Container/ServiceCon.js'
import Service from "../../Pages/NewService/Component/Service.js";
// import Testimonial from "../../Pages/Testimonial/Component/testimonial.js";
import Headers from "../../Pages/Home/Components/headers.js";
import PricePage from "../../Pages/PricingPage/Components/Pricepagedesign.js";
import Brochure from "../../Pages/Brochures/Component/Brochure.js";
import ContactDetails from "../../Client/Containers/ContactDetailsCon.js";
import TestDrive from "../../Client/Containers/testDriveCon.js";
import Footer from "../../Pages/Home/Components/Footer.js";
import Services from "../../Client/Containers/serviceCon.js";
import VehicleEnq from "../../Client/Containers/vehicleEnqCon.js";
import SEO from "../../Common/Helmate/SEO.js";
import { Dashboard } from "@mui/icons-material";
class Routers extends Component {
  render() {
    const { login } = this.props;
    let body;
    console.log(login.type);

    if (login.isHome) {
      body = (
        <div>
          <main style={{ flexGrow: 1 }}>
            <Controller />
            <Routes>
              <Route exact path="/client" element={<Login />} />
              <Route
                path="/"
                element={
                  <>
                   <SEO
                      title="MG Central | Buy MG Cars in Odisha. Bhubaneswar & Berhampur – Premium SUVs & EVs in Odisha"
                      description="MG Central – Your authorized MG dealership in Odisha with showrooms in Bhubaneswar & Berhampur. Explore the latest MG cars, including Comet, Hector, Astor, ZS EV, and Gloster, with cutting-edge technology, safety, and premium comfort. Get the best MG car prices, test drives, financing, and servicing. Visit MG Central today!"
                      keywords="MG Central Odisha, MG showroom Bhubaneswar, MG showroom Berhampur, buy MG car Odisha, MG Hector price Odisha, MG Astor Bhubaneswar, MG ZS EV Berhampur, MG Gloster SUV, best MG cars Odisha, premium SUV, MG test drive Odisha, MG electric vehicles, MG financing, MG car service Odisha, MG vehicle offers"
                    />
                    <Headers />
                    <NavBar />
                    <Home />
                  </>
                }
              />
              <Route
                path="/preownedcar"
                element={
                  <>
                   <SEO
                      title="BPre-Owned Cars | Certified Used Cars for Sale | MG Central Bhubaneswar"
                      description="Explore a wide range of certified pre-owned cars at MG Central Bhubaneswar. Get quality-checked used cars with warranty, financing options, and the best deals. Visit us today!"
                      keywords="pre-owned cars, used cars Bhubaneswar, certified used cars, MG pre-owned, second-hand cars, buy used MG cars, MG Central pre-owned vehicles, best used car deals, used SUV for sale, MG second-hand cars, MG car exchange, MG used car showroom, MG Central Bhubaneswar"
                    />
                    <Headers />
                    <NavBar />
                    <Preownedcars />
                    <Footer />
                  </>
                }
              />
              {/* <Route
                path="/testimonial"
                element={
                  <>
                    <Headers />
                    <NavBar />
                    <Testimonial />
                    <Footer />
                  </>
                }
              /> */}
              <Route
                path="/car-details/:id/:brand"
                element={
                  <>
                   <SEO
                      title="MG Car Details – Specs, Features & Pricing"
                      description="View detailed specifications, features, and prices of MG cars. Compare different MG models and find the best fit for you."
                      keywords="MG car details, MG car specs, MG model comparison, MG features, MG price,Old cars Bhubneswar,Old cars Odisha"
                    />
                    <Headers />
                    <NavBar />
                    <Car1 />
                    <Footer />
                  </>
                }
              />

              <Route
                exact
                path="/Hector"
                element={
                  <>
                   <SEO
                      title="MG Hector | Tech-Loaded SUV with iSMART & AI Features"
                      description="Explore the MG Hector – India's tech-savvy SUV with AI-powered iSMART, a 10.4” infotainment system, ADAS, and a bold design. Test drive now at MG Central!"
                      keywords="MG Hector SUV, MG Hector price, Hector iSMART features, MG Hector Bhubaneswar, MG Hector Odisha, MG Hector showroom, MG SUV India, buy MG Hector, Hector ADAS, MG Central"
                    />
                    <Headers />
                    <Hector />
                  </>
                }
              />
              <Route
                exact
                path="/Hector6seater"
                element={
                  <>
                   <SEO
                      title="MG Hector Plus 6-Seater | Premium SUV with Captain Seats & ADAS"
                      description="Upgrade your drives with the MG Hector Plus 6-Seater, featuring luxurious captain seats, AI-powered iSMART tech, ADAS safety, and a spacious cabin. Book a test drive today at MG Central in Bhubaneswar!"
                      keywords="MG Hector Plus 6-Seater, MG Hector Plus price, MG Hector Plus captain seats, Hector Plus ADAS, MG Hector Plus features, MG Hector Plus Bhubaneswar, MG Hector Plus safety, MG Hector Plus booking, MG SUV 6-seater, MG Central Bhubaneswar"
                    />
                    <Headers />
                    <Hector6seater />
                  </>
                }
              />
              <Route
                exact
                path="/Hector7seater"
                element={
                  <>
                   <SEO
                      title="MG Hector Plus 7-Seater | Ultimate Family SUV with ADAS & AI Tech"
                      description="Experience the MG Hector Plus 7-Seater – the ultimate family SUV with AI-powered iSMART tech, ADAS safety, and extra space for your loved ones. Book a test drive today at MG Central, Bhubaneswar!"
                      keywords="MG Hector Plus 7-Seater, MG Hector Plus price, Hector Plus 7-seater features, MG Hector Plus ADAS, MG Hector Plus iSMART, MG Hector Plus Bhubaneswar, MG Hector Plus safety, MG Hector 7-seater SUV, MG Central Bhubaneswar, best 7-seater SUV in India"
                    />
                    <Headers />
                    <Hector7seater />
                  </>
                }
              />
              {/* //--------------------------Provide by sir uperContent-------------- */}
              <Route
                exact
                path="/Hectorblackstorm"
                element={
                  <>
                   <SEO
                      title="MG Hector Black Storm – Bold & Stylish SUV"
                      description="The MG Hector Black Storm edition offers a striking design, powerful engine, and advanced technology for a thrilling drive."
                      keywords="MG Hector Black Storm, Hector Black, Black Edition SUV, stylish SUV"
                    />
                    <Headers />
                    <Hectorblackstorm />
                  </>
                }
              />
              <Route
                exact
                path="/Gloster"
                element={
                  <>
                   <SEO
                      title="MG Gloster | Premium 7-Seater SUV with ADAS & 4WD | MG Central Bhubaneswar"
                      description="Explore the MG Gloster at MG Central Bhubaneswar. This premium 7-seater SUV offers ADAS, 4WD capability, a powerful engine, and luxurious interiors. Schedule your test drive now!"
                      keywords="MG Gloster, MG Gloster 7-seater, MG Gloster price, MG Gloster Bhubaneswar, MG Gloster Odisha, MG Gloster features, MG Gloster ADAS, MG Gloster 4WD, MG Gloster interiors, MG Gloster variants, MG Central Bhubaneswar"
                    />
                    <Headers />
                    <Gloster />
                  </>
                }
              />
              <Route
                exact
                path="/Glosterblackstorm"
                element={
                  <>
                   <SEO
                      title="MG Gloster Blackstorm | Rugged 7-Seater SUV with Off-Road Capabilities | MG Central Bhubaneswar"
                      description="Introducing the MG Gloster Blackstorm at MG Central Bhubaneswar. This rugged 7-seater SUV features a bold black exterior, advanced safety, off-road capabilities, and premium comfort. Book your test drive today!"
                      keywords="MG Gloster Blackstorm, MG Gloster Blackstorm price, MG Gloster Blackstorm Bhubaneswar, MG Gloster Blackstorm Odisha, MG Gloster Blackstorm features, MG Gloster Blackstorm 7-seater, MG Gloster Blackstorm safety, MG Gloster Blackstorm off-road, MG Central Bhubaneswar"
                    />
                    <Headers />
                    <Glosterblackstorm />
                  </>
                }
              />
              <Route
                exact
                path="/Glosterstorm"
                element={
                  <>
                   <SEO
                      title="MG Gloster Storm Series | Exclusive 7-Seater SUV with 4x4 | MG Central Bhubaneswar"
                      description="Discover the MG Gloster Storm Series at MG Central Bhubaneswar. This exclusive 7-seater SUV offers enhanced off-road capabilities, 4x4 drivetrain, luxurious interiors, and advanced technology. Test drive it today!"
                      keywords="MG Gloster Storm Series, MG Gloster Storm Series price, MG Gloster Storm Series Bhubaneswar, MG Gloster Storm Series Odisha, MG Gloster Storm Series 7-seater, MG Gloster Storm Series off-road, MG Gloster Storm Series 4x4, MG Central Bhubaneswar"
                    />
                    <Headers />
                    <Glosterseries />
                  </>
                }
              />
              <Route
                exact
                path="/Astor"
                element={
                  <>
                   <SEO
                      title="MG Astor | Smart 5-Seater SUV with ADAS & Panoramic Sunroof | MG Central Bhubaneswar"
                      description="Discover the MG Astor at MG Central Bhubaneswar. Experience a feature-rich 5-seater SUV with Advanced Driver Assistance Systems (ADAS), a spacious interior, and premium styling. Book a test drive today!"
                      keywords="MG Astor, MG Astor SUV, MG Astor price, MG Astor Bhubaneswar, MG Astor Odisha, MG Astor features, MG Astor ADAS, MG Astor safety, MG Astor interiors, MG Astor mileage, MG Astor variants, MG Central Bhubaneswar"
                    />
                    <Headers />
                    <Astor />
                  </>
                }
              />
              <Route
                exact
                path="/Comet"
                element={
                  <>
                   <SEO
                      title="MG Comet EV | Compact & Affordable Electric Car with Smart Features | MG Central Bhubaneswar"
                      description="Meet the MG Comet EV at MG Central Bhubaneswar. This compact electric car offers smart connectivity features, efficient performance, and a stylish design. Schedule a test drive today"
                      keywords="MG Comet EV, MG Comet EV electric car, MG Comet EV price, MG Comet EV Bhubaneswar, MG Comet EV Odisha, MG Comet EV range, MG Comet EV battery, MG Comet EV fast charging, MG Comet EV features, MG Comet EV interiors, MG Central Bhubaneswar"
                    />
                    <Headers />
                    <Comet />
                  </>
                }
              />
              <Route
                exact
                path="/contactUs"
                element={
                  <>
                   <SEO
                      title="Contact MG Central | MG Showroom & Service Center in Bhubaneswar"
                      description="Get in touch with MG Central for inquiries, test drives, car servicing, or booking assistance. Visit our MG showroom in Bhubaneswar or call us today!"
                      keywords="MG Central contact, MG showroom contact, MG dealer phone number, MG service center location, MG showroom near me, MG Bhubaneswar contact, MG Odisha showroom, MG Central Bhubaneswar, MG dealership address, MG customer support, MG booking inquiry, MG test drive booking, MG car price inquiry"
                    />
                    <Headers />
                    <Contact />
                  </>
                }
              />
              <Route
                exact
                path="/pricing"
                element={
                  <>
                   <SEO
                      title="MG Cars Price List – Get the Best Deals at MG Central"
                      description="Explore the latest MG car price list, offers, and financing options. Find the best MG car that fits your budget"
                      keywords="MG car price, MG pricing, MG deals, car financing, MG offers"
                    />
                    <Headers />
                    <NavBar />
                    <PricePage />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/ZsEv"
                element={
                  <>
                   <SEO
                      title="MG ZS EV | Long-Range Electric SUV with Fast Charging | MG Central Bhubaneswar"
                      description="Experience the MG ZS EV at MG Central Bhubaneswar. This long-range electric SUV features fast charging, modern safety features, and a spacious interior. Book your test drive now!"
                      keywords="MG ZS EV, MG ZS EV electric car, MG ZS EV price, MG ZS EV Bhubaneswar, MG ZS EV Odisha, MG ZS EV features, MG ZS EV range, MG ZS EV battery, MG ZS EV fast charging, MG ZS EV interiors, MG ZS EV safety, MG Central Bhubaneswar"
                    />
                    <Headers />
                    <ZsEv />
                  </>
                }
              />
              <Route
                exact
                path="/Windsor"
                element={
                  <>
                   <SEO
                      title="MG Windsor EV | Premium CUV with Advanced Safety & Long Range | MG Central Bhubaneswar"
                      description="Discover the MG Windsor EV at MG Central Bhubaneswar. This premium CUV offers advanced safety features, a spacious interior, and an efficient electric performance. Book your test drive now!"
                      keywords="MG Windsor EV, MG Windsor EV electric car, MG Windsor EV price, MG Windsor EV Bhubaneswar, MG Windsor EV Odisha, MG Windsor EV features, MG Windsor EV range, MG Windsor EV battery, MG Windsor EV fast charging, MG Windsor EV interiors, MG Windsor EV safety, MG Central Bhubaneswar"
                    />
                    <Headers />
                    <Windsor />
                  </>
                }
              />
              <Route
                exact
                path="/car"
                element={
                  <>
                   <SEO
                      title="MG Cars 360° View – Explore Every Angle"
                      description="Get a 360-degree view of MG cars and explore their exterior and interior details in high definition."
                      keywords="MG car 360 view, virtual car tour, MG vehicle interior, MG car exterior"
                    />
                    <Headers />
                    <Car360 />
                  </>
                }
              />
              <Route
                exact
                path="/aboutUs"
                element={
                  <>
                   <SEO
                      title="About MG Central | Authorized MG Dealership in Odisha"
                      description="Learn more about MG Central, the leading authorized MG dealership in Bhubaneswar. Offering the latest MG vehicles, genuine service, and customer-first experience."
                      keywords="MG Central, MG dealership Bhubaneswar, MG showroom Odisha, about MG Central, MG car dealer, MG authorized dealership, MG showroom near me, MG cars Odisha, MG Central Bhubaneswar, MG cars India, MG brand history, MG dealer network, MG Central location"
                    />
                    <Headers />
                    <About />
                  </>
                }
              />
              <Route
                exact
                path="/brochure"
                element={
                  <>
                   <SEO
                      title="Download MG Cars Brochure – Features & Specifications"
                      description="Download the latest MG car brochures to explore specifications, features, and variants in detail."
                      keywords="MG car brochure, MG specs, MG car details, MG model catalog"
                    />
                    <Headers />
                    <NavBar />
                    <Brochure />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/service"
                element={
                  <>
                   <SEO
                      title="MG Car Service & Maintenance | Book Service Online | MG Central Bhubaneswar"
                      description="Get expert MG car servicing, repairs, and maintenance at MG Central Bhubaneswar. Book your service appointment online for hassle-free servicing and genuine MG parts"
                      keywords="MG car service, MG servicing, MG maintenance, MG car repair, MG service center Bhubaneswar, book MG service, MG oil change, MG vehicle check-up, MG spare parts Bhubaneswar, MG car workshop, MG car diagnostics, MG car insurance claim, MG car accident repair, MG Central Bhubaneswar"
                    />
                    <Headers />
                    <NavBar />
                    <Service />
                    <Footer />
                  </>
                }
              />
              {/* <Route
                exact
                path="/addinfo"
                element={
                  <>
                   <SEO
                      title=""
                      description=""
                      keywords=""
                    />
                    <Clientnav />
                    <Addinginfo />
                    <Footer />
                  </>
                }
              /> */}
              {/* <Route
                exact
                path="/overview"
                element={
                  <>
                   <SEO
                      title=""
                      description=""
                      keywords=""
                    />
                    <Clientnav />
                    <Overview />
                    <Footer />
                  </>
                }
              /> */}
              {/* <Route
                exact
                path="/specification"
                element={
                  <>
                   <SEO
                      title=""
                      description=""
                      keywords=""
                    />
                    <Clientnav />
                    <Specification />
                    <Footer />
                  </>
                }
              /> */}
              {/* <Route
                exact
                path="/feature"
                element={
                  <>
                   <SEO
                      title="MG Cars Features – Advanced Tech & Safety Innovations"
                      description="Explore the innovative features of MG cars, including AI technology, safety systems, and premium comfort."
                      keywords="MG car features, AI car, MG safety, MG technology"
                    />
                    <Clientnav />
                    <Features />
                    <Footer />
                  </>
                }
              /> */}
            </Routes>
          </main>
        </div>
      );
    } else {
      switch (login.type) {
        case "SA":
          console.log(login.type);
          body = (
            <div>
              {/* <Drawer type={login.type} /> */}
              <main style={{ flexGrow: 1, paddingTop: 0 }}>
                <Controller />
                <Routes>
                  <Route
                    exact
                    path="/client"
                    element={
                      <>
                        <Admindashboard />
                      </>
                    }
                  />
                  
                  {/* <Route
                    exact
                    path="/dashboard"
                    element={
                      <>
                        <Admindashboard />
                      </>
                    }
                  /> */}
                  <Route
                    exact
                    path="/advertisement"
                    element={
                      <>
                        <Clientnav /> <Advertisement />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/contactUs-details"
                    element={
                      <>
                        <Clientnav /> <ContactDetails />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/service-request"
                    element={
                      <>
                        <Clientnav /> <Services />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/vehicle-enquiry"
                    element={
                      <>
                        <Clientnav /> <VehicleEnq />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/oldcar"
                    element={
                      <>
                        <Clientnav /> <Oldcar />
                      </>
                    }
                  />

                  <Route
                    exact
                    path="/book-test-drive"
                    element={
                      <>
                        <Clientnav /> <TestDrive />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/addinfo"
                    element={
                      <>
                        <Clientnav />
                        <Addinginfo />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/overview"
                    element={
                      <>
                        <Clientnav />
                        <Overview />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/specification"
                    element={
                      <>
                        <Clientnav />
                        <Specification />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/feature"
                    element={
                      <>
                        <Clientnav />
                        <Features />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/edit/:id"
                    element={
                      <>
                        <Clientnav /> <EditPage />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/view/:id"
                    element={
                      <>
                        <Clientnav /> <View />
                        <Footer />
                      </>
                    }
                  />

                  <Route exact path="/Profile" element={<Profile />} />

                  <Route exact path="/contactUs" element={<Contact />} />
                  
                </Routes>
              </main>
            </div>
          );
          break;
        case "E":
          body = (
            <div>
              <Drawer type={login.type} />
              <main style={{ flexGrow: 1, paddingTop: 70 }}>
                <Controller />
                <Routes>
                  <Route exact path="/Profile" element={<Profile />} />
                </Routes>
              </main>
            </div>
          );
          break;
        default:
          body = (
            <div>
              <Drawer type={login.type} />
              <main style={{ flexGrow: 1, paddingTop: 70 }}>
                <Controller />
                <Routes>
                  <Route exact path="/client" element={<Login />} />
                </Routes>
              </main>
            </div>
          );
          break;
      }
    }

    return <Router>{body}</Router>;
  }
}

export default Routers;

import { React, Component } from "react";
import {
  Typography,
  Grid,
  Box,
  Button,
  Divider,
  InputBase,
  IconButton,
} from "@mui/material";
import Astor from "../../Brochures/Astor.pdf";
import Hector from "../../Brochures/Hector.pdf";
import Hector6s from "../../Brochures/Hector6s.pdf";
import Hector7s from "../../Brochures/Hector7s.pdf";
import Gloster from "../../Brochures/gloster.pdf";
import Comet from "../../Brochures/comet.pdf";
import Zs from "../../Brochures/zsev.pdf";
import Windsor from "../../Brochures/windsor.pdf";
import AstorPrice from "../../Price downloads/Price List Astor.pdf";
import HectorPrice from "../../Price downloads/Price List Hector.pdf";
import CometPrice from "../../Price downloads/Price List Comet EV.pdf";
import ZsPrice from "../../Price downloads/Price List ZS EV.pdf";
import WindsorPrice from "../../Price downloads/Price List Windsor EV.pdf";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import googleplay from "../../images/googleplay.png";
import appstore from "../../images/app.png";
import { Link } from "react-router-dom";

class Footer extends Component {
  handleClick = (e) => {
    e.preventDefault();
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.location.href = "tel:9090034282"; // Initiates the call on mobile
    } else {
      alert("Please call 9090034282 from your mobile device."); // Shows message on desktop
    }
  };
  render() {
    return (
      <>
        {/* Footer */}
        <Box
          sx={{
            width: "100%",
            minHeight: { md: "300px", xs: "500px" },
            backgroundColor: "#000000",
            color: "white",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 2 },
          }}
        >
          {/* Left Section */}
          <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: { xs: 'center', md: 'left' },
          width: { xs: '100%', md: '30%' },
          padding: { xs: '50px', md: '3%'},
          gap: 2,
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px', justifyContent: 'center' }}>
            <input type="checkbox" id="check" style={{ height: '20px' }} />
            <label htmlFor="check" style={{ fontSize: '10px' }}>
              {/* I agree to the <a href="#" style={{ color: 'white' }}><u>privacy policy</u></a> */}
            </label>
          </Box>
          <Typography fontSize="11px">Call us at 07941050770</Typography>
          <Typography fontSize="11.5px">Mail us at sales@mgcentral.in</Typography>
        </Box>
          <Divider sx={{ my: 2, borderColor: "whitesmoke" }} />

          {/* Right side */}
          <Box
            sx={{
              marginTop: "20px",
              width: "100%",
              minHeight: "200px",
              backgroundColor: "#000000",
              color: "white",
              p: 2,
            }}
          >
            <Grid
              container
              spacing={4}
              wrap="nowrap"
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              {/* <Grid item xs={12} md={2}> */}
              {/* <Typography variant="h6" sx={{ fontSize: '12px' }} textAlign='start' fontFamily= 'RobotoMedium,sans-serif' fontWeight="500">BRAND</Typography><br/> */}
              {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems:'start',gap: '8px', marginTop: '10px' }}> */}
              {/* <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>History</a> */}
              {/* <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Innovation</a> */}
              {/* <a href="/aboutUs" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>About MG</a> */}
              {/* <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Pillars</a> */}
              {/* <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Culture at MG</a> */}
              {/* <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Stories of MG</a> */}
              {/* <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Safety Fast</a> */}
              {/* </Box> */}
              {/* </Grid> */}
              {/* <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} /> */}

              {/* <Grid item xs={12} md={1.71}>
      <Typography variant="h6" sx={{ fontSize: '12px' }} textAlign='start' fontFamily= 'RobotoMedium,sans-serif' fontWeight="300">WORLD OF MG</Typography><br/>
      <Box sx={{ display: 'flex', flexDirection: 'column',alignItems:'start', gap: 1, marginTop: '10px' }}>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>What's New</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Events</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Campaigns</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>i-Smart</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG V Phy</a>
        <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Stories of MG</a>
      </Box>
    </Grid>
    <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} /> */}

              <Grid item xs={12} md={3}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "12px" }}
                  textAlign="start"
                  fontFamily="RobotoMedium,sans-serif"
                  fontWeight="300"
                >
                  HELP
                </Typography>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    gap: 1,
                    marginTop: "10px",
                  }}
                >
                  <Link
                    to="/contactUs"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    Contact Us
                  </Link>
                  <Link
                    to="/preownedcar"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    Dealer Locator
                  </Link>
                  <Typography
                    onClick={this.handleClick}
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                      cursor: "pointer",
                    }}
                  >
                    Connect with MG Expert
                  </Typography>
                </Box>
              </Grid>
              <Divider sx={{ my: 2, borderColor: "whitesmoke" }} />

              {/* <Grid item xs={12} md={2}> */}
              {/* <Typography variant="h6" sx={{ fontSize: '12px' }} textAlign='start' fontFamily= 'RobotoMedium,sans-serif' fontWeight="300">MEDIA</Typography><br/> */}
              {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'start', marginTop: '10px' }}> */}
              {/* <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Dealership</a> */}
              {/* <a href="/contactUs" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Contact Us</a> */}
              {/* <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Store Locator</a> */}
              {/* <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Careers</a> */}
              {/* <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>FAQ</a> */}
              {/* <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>MG Global Footprint</a> */}
              {/* <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Astor Delivery Status</a> */}
              {/* <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>EHS Policy</a> */}
              {/* </Box> */}
              {/* </Grid> */}
              {/* <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} /> */}

              <Grid item xs={12} md={3}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "12px" }}
                  textAlign="start"
                  fontFamily="RobotoMedium,sans-serif"
                  fontWeight="300"
                >
                  VEHICLES
                </Typography>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "start",
                    marginTop: "10px",
                  }}
                >
                  <Link
                    to="/Astor"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Astor
                  </Link>
                  <Link
                    to="/Hector"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Hector
                  </Link>
                  <Link
                    to="/Hector6seater"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Hector Plus(6S)
                  </Link>
                  <Link
                    to="/Hector7seater"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Hector Plus(7S)
                  </Link>
                  <Link
                    to="/ZsEv"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG ZS EV
                  </Link>
                  <Link
                    to="/Gloster"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Gloster
                  </Link>
                  <Link
                    to="/Comet"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Comet EV
                  </Link>
                  <Link
                    to="/Windsor"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Windsor EV
                  </Link>
                </Box>
              </Grid>
              <Divider sx={{ my: 2, borderColor: "whitesmoke" }} />

              <Grid item xs={12} md={3}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "12px" }}
                  textAlign="start"
                  fontFamily="RobotoMedium,sans-serif"
                  fontWeight="300"
                >
                  PRICE
                </Typography>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "start",
                    marginTop: "10px",
                  }}
                >
                  <a
                    href={AstorPrice}
                    download="MG_Astor_Price.pdf"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    Astor Price
                  </a>
                  <a
                    href={HectorPrice}
                    download="MG_Hector_Price.pdf"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    Hector Price
                  </a>
                  {/* <a href="/Hector6seater" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Hector Plus(6S) Price</a> */}
                  {/* <a href="/Hector7seater" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}>Hector Plus(7S) Price</a> */}
                  <a
                    href={ZsPrice}
                    download="MG_ZS_Price.pdf"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    ZS EV Price
                  </a>
                  
                  <a
                    href={CometPrice}
                    download="MG_Comet_EV_Price.pdf"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    Comet EV Price
                  </a>
                  <a
                    href={WindsorPrice}
                    download="MG_Windsor-EV_Price.pdf"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    Windsor EV Price
                  </a>
                </Box>
              </Grid>
              <Divider sx={{ my: 2, borderColor: "whitesmoke" }} />
            
              <Grid item xs={12} md={3}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "12px" }}
                  textAlign="start"
                  fontFamily="RobotoMedium,sans-serif"
                  fontWeight="300"
                >
                  BROCHURES
                </Typography>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "start",
                    marginTop: "10px",
                  }}
                >
                  <a
                    href={Astor}
                    download="MG_Astor_Brochure.pdf"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Astor Brochure
                  </a>
                  <a
                    href={Hector}
                    download="MG_Hector.pdf"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Hector Brochure
                  </a>
                  <a
                    href={Hector6s}
                    download="MG_Hector_6seater.pdf"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Hector Plus(6S) Brochure
                  </a>
                  <a
                    href={Hector7s}
                    download="MG_Hector_7seater.pdf"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Hector Plus(7S) Brochure
                  </a>
                  <a
                    href={Zs}
                    download="MG_ZS_EV.pdf"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG ZS EV Brochure
                  </a>
                  <a
                    href={Gloster}
                    download="MG_Gloster.pdf"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Gloster Brochure
                  </a>
                  <a
                    href={Comet}
                    download="MG_Comet.pdf"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Comet EV Brochure
                  </a>
                  <a
                    href={Windsor}
                    download="MG_Windsor.pdf"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                      fontSize: "10px",
                    }}
                  >
                    MG Windsor EV Brochure
                  </a>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Icon part */}
        <Box
          sx={{
            backgroundColor: "#000000",
            color: "white",
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            width: "100%",
            p: 2,
            gap: "10px",
          }}
        >
          <IconButton
            sx={{ color: "grey", border: "1px solid grey" }}
            component="a"
            href="https://www.facebook.com/share/yJVWYx3n5N4wnfrx/?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            sx={{ color: "grey", border: "1px solid grey" }}
            component="a"
            href="https://www.instagram.com/mg.central?igsh=ZjdjOXkxenppNGNs&utm_source=qr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            sx={{ color: "grey", border: "1px solid grey" }}
            component="a"
            href="https://api.whatsapp.com/send?phone=918599005857&text="
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon />
          </IconButton>

          
        </Box>
      </>
    );
  }
}

export default Footer;

import React from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";

const Header = () => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: "rgba(0,0,0)",
        height: "40px",
        alignItems: "center",
        position: "fixed",
        top: 0, 
        width: "100%", 
        zIndex: 1000, 
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingRight: "10px", // Add some spacing from the right
        }}
      >
        <IconButton edge="end" color="inherit" aria-label="call">
          <PhoneIcon style={{ color: "white" }} />
        </IconButton>
        <Typography
          sx={{
            fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
            fontSize: { xs: "10px", sm: "16px" },
            color: "white",
            paddingX: "15px",
          }}
        >
          <strong>Call Us: 07941050770</strong>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Header;
